$offset: 10px;

.journal-table {
  border-spacing: 0 8px;

  td {
    @apply bg-neutral-100 border-neutral-100;
    height: 55px;

    &:first-child, {
      border-radius: var(--tui-radius-m) 0 0 var(--tui-radius-m);
    }

    &:last-child {
      border-radius: 0 var(--tui-radius-m) var(--tui-radius-m) 0;
    }
  }

  tfoot td {
    border-radius: var(--tui-radius-m)!important;
    padding: 0 12px;

    tui-table-pagination {
      margin: 0;
    }
  }

  tbody {
    td:last-child {
      position: relative;

      &:before {
        position: absolute;
        top: $offset;
        right: $offset;
        content: '';
        background: var(--tui-primary);
        width: 2px;
        height: calc(100% - $offset * 2);
      }
    }
  }

  th {
    border-color: transparent;
  }

}

.journal-table + tui-table-pagination{
  @apply sticky bottom-0 py-4 w-full right-0 left-0 bg-white
}
